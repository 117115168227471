import styled from "styled-components";
import media from "styled-media-query";
import Text from "app/components/Text";
import { Link } from "react-router-dom";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Button from "app/components/Button";

export const Wrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mediaQueries.ltlg} {
    margin-top: 16px;
  }
`;

export const A = styled.a`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: underline;

  &:hover {
    text-decoration-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const ProviderButton = styled(Button)`
  padding: 0;
  border: 1.5px solid ${({ theme }) => theme.colors.black};
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.monochrome[1]};
  }
`;

export const ProviderButtonText = styled(Text)`
  color: black;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.01em;

  ${media.lessThan("small")`
    font-size: 14px;
  `}
`;

export const StyledCtaLink = styled(Link)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blue};

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const CtaWrapper = styled(Div)`
  margin-top: 16px;
  text-align: left;
  letter-spacing: 0.04em;

  span {
    font-weight: 400;
    font-size: 12px;
  }

  span:nth-of-type(1) {
    margin-right: 0.5em;
  }
`;
